import Main from "@pacvue/frame/layout/Main.vue"

export default {
  router: {
    path: "/",
    component: Main,
    // meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Optimization/Rule",
        component: () => import("@rule/Index.vue"),
        name: "Rule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/CreateRule/CommerceRule",
        redirect: "/Optimization/Rule/CommerceRule"
      },
      {
        path: "/Optimization/Rule/CommerceRule",
        component: () => import("@rule/CreateRules/CommerceRule.vue"),
        name: "CommerceRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/CompetitorRule",
        component: () => import("@rule/CreateRules/CompetitorRule.vue"),
        name: "CompetitorRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/InventoryRule",
        component: () => import("@rule/CreateRules/InventoryRule.vue"),
        name: "InventoryRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      // {
      //   path: "/Optimization/Rule/InventoryRule",
      //   component: () => import("@rule/CreateRules/InventoryRule.vue"),
      //   name: "InventoryRule",
      //   meta: {
      //     parent: "Optimization",
      //     alias: "Opt_RBO"
      //   }
      // },
      {
        path: "/Optimization/CreateRule/Common",
        redirect: "/Optimization/Rule/Common"
      },
      {
        path: "/Optimization/Rule/Common",
        component: () => import("@rule/CreateRules/Common.vue"),
        name: "CommonRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/CreateRule/HarvestKeywordsRule",
        redirect: "/Optimization/Rule/HarvestKeywordsRule"
      },
      {
        path: "/Optimization/Rule/HarvestKeywordsRule",
        component: () => import("@rule/CreateRules/HarvestKeywordsRule.vue"),
        name: "HarvestKeywordsRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/CreateRule/NegativeTargetingRule",
        redirect: "/Optimization/Rule/NegativeTargetingRule"
      },
      {
        path: "/Optimization/Rule/NegativeTargetingRule",
        component: () => import("@rule/CreateRules/NegativeTargetingRule.vue"),
        name: "NegativeTargetingRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/CommonNew",
        component: () => import("@rule/CreateRules/CommonNew.vue"),
        name: "CommonNew",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/SOVBidRule",
        component: () => import("@rule/CreateRules/SOVBidRule.vue"),
        name: "SOVBidRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/AutoRefillRule",
        component: () => import("@rule/CreateRules/AutoRefillRule.vue"),
        name: "AutoRefillRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/DSPCreativeRule",
        component: () => import("@rule/CreateRules/DSPCreativeRule.vue"),
        name: "DSPCreativeRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/ProfiteroRule",
        component: () => import("@rule/CreateRules/ProfiteroRule.vue"),
        name: "ProfiteroRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/BulkCreate",
        component: () => import("@rule/CreateRules/BulkCreateRules.vue"),
        name: "BulkCreateRules",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/DataImpactRule",
        component: () => import("@rule/CreateRules/DataImpactRule.vue"),
        name: "DataImpactRule",
        meta: {
          parent: "Optimization",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/RunRecord",
        component: () => import("@rule/RunRecord.vue"),
        name: "Manual Rule Run Record",
        meta: {
          title: "Manual Rule Run Record",
          alias: "Opt_RBO"
        }
      },
      {
        path: "/Optimization/Rule/OperationLog",
        component: () => import("@rule/OperationLog.vue"),
        name: "Rule Log",
        meta: {
          alias: "Opt_RBO"
        }
      }
    ]
  }
}
