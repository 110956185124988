const whiteLabelMap = new Map([
  [
    ["groupm.pacvue", "groupmeurope.pacvue"],
    {
      isThemeFixed: true,
      themeColors: {
        themeColor: "#0014CC",
        themeBG3: "#0A2FFF",
        themeBG5: "#85A3FF",
        themeBG8: "#C2D4FF",
        themeBG9: "#E0EBFF",
        themeFSC: "#FDE212",
        themeRMH: "#fde212", // Menu 高亮色
        themeRMHT: "#000785", // Menu 高亮色中的文字色
        themeTHC: "#F0F7FF", // Table 专用 Hover 色
        themeRGB: "0, 20, 204"
      },
      globalLoadingSVG: "GroupM",
      logoPNG: "groupm.pacvue.com",
      tinyLogoPNG: "groupm.pacvue.com",
      loginIllustrator: "GroupM",
      favTitle: "GroupM",
      isPoweredByPacvueTitle: true,
      favIcon: "https://www.groupm.com/wp-content/themes/bigdrop-theme/dist/images/favicon-32x32.png",
      topBarTheme: "white",
      topBarBgColor: "#fff",
      topBarTextColor: "#45464f",
      sideBarBgColor: "#000050",
      sideBarBgSubColor: "#000333",
      homeTopCardBgColors: ["#85a3ff", "#6ce07b", "#ecc706"]
    }
  ],
  [
    ["commerce.lungemarketing"],
    {
      isThemeFixed: true,
      themeColors: {
        themeColor: "#87288F",
        themeBG3: "#a840b0",
        themeBG5: "#85A3FF",
        themeBG8: "#C2D4FF",
        themeBG9: "#E0EBFF",
        themeFSC: "#FDE212",
        themeRMH: "#fde212", // Menu 高亮色
        themeRMHT: "#56175d", // Menu 高亮色中的文字色
        themeTHC: "#F0F7FF", // Table 专用 Hover 色
        themeRGB: "0, 20, 204"
      },
      globalLoadingSVG: "",
      logoPNG: "",
      tinyLogoPNG: "",
      loginIllustrator: "",
      favTitle: "Lunge Marketing",
      favIcon: "",
      topBarTheme: "white",
      topBarBgColor: "#fff",
      topBarTextColor: "#45464f",
      sideBarBgColor: "#340d38",
      sideBarBgSubColor: "#170418",
      homeTopCardBgColors: ["#85a3ff", "#6ce07b", "#ecc706"]
    }
  ]
])

const hostname = window.location.hostname
// 需要定制跳转的 Clients
const hostnameClientsMap = {
  "groupm.pacvue.com": [
    "106",
    "318",
    "515",
    "551",
    "611",
    "623",
    "636",
    "684",
    "707",
    "711",
    "728",
    "744",
    "790",
    "838",
    "857",
    "866",
    "877",
    "878",
    "914",
    "938",
    "955",
    "971",
    "984",
    "1004",
    "1030",
    "1042",
    "1046",
    "1103",
    "1135",
    "1167",
    "1174",
    "1182",
    "1247",
    "1350",
    "1387",
    "1404",
    "1428",
    "1474",
    "1634",
    "1800",
    "1848",
    "2007",
    "2095",
    "2210",
    "2527",
    "2568",
    "2571",
    "2749",
    "2954",
    "2995",
    "3140",
    "3345",
    "3358",
    "3380",
    "3433",
    "3472",
    "3474",
    "3484",
    "3623",
    "3624",
    "3625",
    "3642",
    "3927",
    "3940"
  ],
  "groupmeurope.pacvue.com": ["790", "200035", "200044", "200059", "210062", "210184", "210343", "210454", "210492", "210549", "210556", "210558", "210581", "210596", "210598"]
}
const whiteLabelsConfig = []
const iter = whiteLabelMap.entries()
let entry
// 访问直至迭代器 Exhausted
while ((entry = iter.next().value) !== undefined) {
  whiteLabelsConfig.push(entry)
}

// 独立白名单站点 (无左上角跳转)
const independentHosts = ["commerce.lungemarketing.com"]

const headerTailoredHostsConfig = {
  "portal.revmo.com": {
    topBarBgColor: "#710000",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  },
  "ams.revivemedia.us": {
    topBarBgColor: "#8E68F4",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  },
  "audacy.pacvue": {
    topBarBgColor: "#7A3BCB",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  },
  canyonwallinsights: {
    topBarBgColor: "#1451FF",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  },
  "allianceclientsolutions.com": {
    topBarBgColor: "#0071CE",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  }
}

let headerTailoredConfig = null

for (const host in headerTailoredHostsConfig) {
  if (hostname.includes(host)) {
    headerTailoredConfig = headerTailoredHostsConfig[host]
    break
  }
}

export { whiteLabelsConfig, independentHosts, headerTailoredConfig, hostnameClientsMap }
